.root {
  position: relative;
  height: 400px;
}
.root .regular {
  height: 400px;
}

.root .fullscreen {
  z-index: 10;
  border: 1px solid #000;
  position: fixed;
  top: 50px;
  left: 50px;
  width: calc(100% - 100px);
  height: calc(100% - 100px);
}

.root .viewModeSelector {
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 10px;
}

.root .fullscreenButton {
  position: absolute;
  z-index: 10;
  bottom: 10px;
  right: 10px;
}

.root .atpButton {
  position: absolute;
  z-index: 10;
  top: 10px;
  left: 10px;
}
