.space {
  width: 100%;
}

.button {
  height: auto;
}

.button span {
  white-space: break-spaces;
}