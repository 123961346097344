.App {
  background-color: #20A043;
  min-height: 100%;
}

.formContainer {
  min-height: 300px;
  background-color: #FEFEFE;
  margin-top: 20px;
  padding-bottom: 30px;
}

.copyright {
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 30px;
}

:global(.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after) {
  background-color: #D8D8D8;
}

:global(.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after) {
  background-color: #D8D8D8;
}