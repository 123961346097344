.root {
  position: relative;
}

.root h1 {
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: rgb(32, 81, 67);
}

.root .logoContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.root .logoContainer .logo {
  width: 200px;
  display: block;
}

.root .phoneNumber {
  position: absolute;
  right: 20px;
  bottom: 0px;
  font-size: 14px;
  text-align: right;
}