.root {

}

.root .socialRow {
  background-color: #F0F0F0;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 10px;
}

.siteCollapsed {
  background-color: #F0F0F0;
  border-radius: 4px;
  padding: 10px 20px;
  margin-bottom: 10px;
}

.collapsable > :global(.ant-col.ant-form-item-control) > :global(.ant-form-item-control-input) {
  min-height: 0;
}

.scheduleContainer > :global(.ant-col.ant-form-item-control) {
  width: 100%;
}