.root .previews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.root .picturePreview {
  width: 330px;
  height: 176px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}


.root .appPreview {
  width: 330px;
  margin-right: 10px;
  box-sizing: content-box;
  border: 1px solid #333;
}

.root .appPreview .appContent {
  padding: 10px 16px;
}

.root .appPreview .title {
  color: rgb(32, 81, 67);
  font-weight: bolder;
  font-size: 18px;
}

.root .appPreview .subtitle {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 10px;
}

.root .appPreview .types {
  font-size: 13px;
  padding-left: 10px;
  margin-bottom: 4px;
}

.root .appPreview .categoryTitle {
  margin-top: 10px;
  margin-bottom: 5px;
  color: rgb(32, 81, 67);
  font-weight: bolder;
  font-size: 16px;
}

.root .appPreview .categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.root .appPreview .productCategory {
  width: 65px;
  height: 65px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #F0F0F0;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.root .appPreview .chipContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px 0;
}

.root .appPreview .chipContainer .chip {
  background-color: #EFEDDB;
  color: #D46330;
  font-size: 13px;
  padding: 5px 10px;
  border-radius: 20px;
  margin: 0 5px 5px 0;

}

.root .appPreview .paragraph {
  white-space: pre-line;
}

.root .appPreview .schedule {
  width: 100%;
}

.root .appPreview .scheduleLine {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.root .appPreview .schedule .day {
  flex: 1;
}

.root .appPreview .schedule .value {
  white-space: pre-line;
}
