.root {
  margin-top: 20px;
}

.root .formContent {
  margin-top: 40px;
}

.root .picturePreview {
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.root .socialLinks > :global(.ant-form-item-control) {
  width: 100%;
}